import { down } from "styled-breakpoints";
import styled from "styled-components";

import { Button } from "ds/Button";
import {
  BorderRadius300,
  ColorBrandDefault,
  ColorNeutralWhite,
  Space100,
  Space150,
  Space200,
  Space400,
  SpaceNone,
} from "ds/Core";
import { typographyTokens } from "ds/theme";

import { ModalProps, ModalType } from "./types";

export const StyledOverlay = styled.div`
  position: fixed;
  top: ${SpaceNone};
  left: ${SpaceNone};
  background-color: ${ColorBrandDefault}b3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 99999;
  transition: opacity 0.25s;
  opacity: 0;

  &.modal-enter {
    opacity: 0;
  }

  &.modal-enter-done {
    opacity: 1;
  }

  &.modal-exit {
    opacity: 1;
  }

  &.modal-exit-active {
    opacity: 0;
  }
`;

export const StyledModal = styled.div<ModalProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  min-width: 529px;
  max-height: calc(100vh - ${Space400});
  background: ${ColorNeutralWhite};
  padding: ${Space400} ${SpaceNone};
  position: relative;

  box-shadow:
    0px 8px 15px 2px rgba(0, 0, 0, 0.1),
    0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: ${BorderRadius300};

  ${down("md")} {
    width: calc(100% - ${Space200});
    min-width: 0px;
  }
`;

export const StyledContent = styled.div`
  overflow-y: auto;
  margin-top: ${Space400};
  padding: ${SpaceNone} ${Space400};
  font-family: ${typographyTokens.copy.medium100.fontFamily};
  font-weight: ${typographyTokens.copy.medium100.fontWeight};
  line-height: ${typographyTokens.copy.medium100.lineHeight};
  font-size: ${typographyTokens.copy.medium100.fontSize};
`;

export const StyledActions = styled.div<{ type: ModalType }>`
  display: flex;
  gap: ${Space150};
  flex-direction: row;

  ${({ type }) => type === "STEPS" && "flex-direction: row-reverse; justify-content: end;"}
`;

export const StyledFooter = styled.div`
  margin-top: ${Space400};
  padding: ${SpaceNone} ${Space400};
`;

export const StyledCloseButton = styled(Button)`
  position: absolute;
  right: ${Space100};
  top: ${Space100};
`;
