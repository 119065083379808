import React from "react";
import { down } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";

import { Box } from "ds/Box";
import { Button } from "ds/Button";
import { Dropdown } from "ds/Dropdown";
import { DropdownProps } from "ds/Dropdown/src/types";
import { ButtonProps } from "ds/main";

import { ActionType, ActionsType } from "../types";

const Actions = ({ actions }: { actions?: ActionType[] }) => {
  const isDownLg = useBreakpoint(down("lg"));
  return actions?.length ? (
    <Box alignItems="center" flexDirection="row-reverse" gap={isDownLg ? "50" : "100"} pr={isDownLg ? "50" : "100"}>
      {actions?.map(({ actionType = ActionsType.BUTTON, ...action }, index) =>
        actionType === ActionsType.DROPDOWN ? (
          <Dropdown key={index} size="small" {...(action as Omit<DropdownProps, "size" | "variation" | "position">)} />
        ) : (
          <Button
            key={index}
            variation={index === 0 ? "primary" : "secondary"}
            size="small"
            {...(action as Omit<ButtonProps, "size" | "variation">)}
          />
        )
      )}
    </Box>
  ) : null;
};

export { Actions };
