import { FeatureFlag, isEnabledFeature } from "utils/featureFlags";

import { DEFAULT_LOCALE } from "../constants";

export const setLocaleCookie = (locale: string) => (document.cookie = `locale=${locale}; path=/; max-age=34560000;`);

export const getLocaleCookie = () =>
  // context(alexandrchebotar, 2024-12-10): don't use locale from cookies when the feature flag is disabled
  isEnabledFeature(FeatureFlag.LOCALES)
    ? document.cookie
        .split("; ")
        .find((row) => row.startsWith("locale="))
        ?.split("=")[1]
    : DEFAULT_LOCALE;
