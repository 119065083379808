import React from "react";
import styled from "styled-components";
import { background, color, height, space, typography, width } from "styled-system";

import { FontFamilyInter, Space100 } from "ds/Core";
import { Spinner } from "ds/Spinner";
import { Tooltip } from "ds/Tooltip/src/Tooltip";
import { aliasTokens, coreTokens } from "ds/theme";
import { Override } from "ds/types";

import { getButtonStyles, getIconColor, getIconSize, getSpinnerSize } from "./theme";
import { ButtonBorderRadiusDefault } from "./tokens";
import { ButtonProps, ButtonStyleProps } from "./types";

const StyledButton = styled.button<Override<ButtonStyleProps, { loading?: number }>>`
  font-family: ${FontFamilyInter};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${Space100};
  border: none;
  border-radius: ${ButtonBorderRadiusDefault};
  cursor: pointer;

  ${(props) => getButtonStyles(props)}

  ${({ text, loading }) => !!(loading && text) && "min-width: 120px;"}

  ${background}
  ${color}
  ${space} 
  ${width}
  ${height}
  ${typography}

  ${({ background }) =>
    background &&
    `&:hover { background: ${coreTokens.color[background] ?? aliasTokens.color[background]}; opacity: 0.8; }`}
`;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      icon,
      iconRight,
      loading,
      text,
      size = "medium",
      url,
      target = "_blank",
      onClick,
      variation = "primary",
      tooltip,
      disabled,
      active,
      ...rest
    },
    ref
  ) => {
    const href = variation === "text" ? url : undefined;
    const onMouseUp =
      variation === "text"
        ? undefined
        : (e: React.MouseEvent) => {
            if (url) {
              if (e.button === 0) {
                window.open(url, target);
              }
              if (e.button === 1) {
                window.open(url, "_blank");
              }
            }
          };
    const { content, ...tooltipProps } = tooltip ?? {};

    return (
      <Tooltip
        {...tooltipProps}
        trigger={
          <StyledButton
            as={variation === "text" ? "a" : "button"}
            type={variation === "text" ? undefined : "button"}
            href={href ?? url}
            target={target}
            icon={icon}
            iconRight={iconRight}
            iconType={icon && !text}
            onClick={onClick}
            onMouseUp={onMouseUp}
            ref={ref}
            size={size}
            text={text}
            variation={variation}
            loading={+!!loading}
            disabled={disabled}
            active={active}
            {...rest}
          >
            {loading ? (
              <Spinner size={getSpinnerSize(size)} />
            ) : (
              <>
                {icon &&
                  React.cloneElement(icon, {
                    size: icon.props.size ?? getIconSize(size),
                    color: icon.props.color ?? getIconColor({ variation, disabled }),
                  })}
                {text && <span>{text}</span>}
                {iconRight &&
                  React.cloneElement(iconRight, {
                    size: iconRight.props.size ?? getIconSize(size),
                    color: iconRight.props.color ?? getIconColor({ variation, disabled }),
                  })}
              </>
            )}
          </StyledButton>
        }
      >
        {content}
      </Tooltip>
    );
  }
);
Button.displayName = "Button";

export { Button };

/* todo(alexandrchebotar, 2022-10-30): 
        1. Types
        2. Add docs
        3. Add destructive styles for secondary and flat buttons (requires design update)
*/
