import React, { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";

import { Box } from "ds/Box";
import { Button } from "ds/Button";
import { Heading, Text } from "ds/Typography";
import { Icon } from "ds/icons";

import { ReactPortal } from "./Portal";
import { StyledActions, StyledCloseButton, StyledContent, StyledFooter, StyledModal, StyledOverlay } from "./Styles";
import { ModalProps } from "./types";

const ModalContent = ({
  title,
  type = "DEFAULT",
  tertiaryAction,
  secondaryAction,
  primaryAction,
  children,
  onHide,
  footer,
  steps,
  contentProps,
  ...props
}: Omit<ModalProps, "overlayProps">) => {
  const useFooter = footer || primaryAction || secondaryAction || tertiaryAction || steps;

  return (
    <StyledModal {...props}>
      <Box height="100%" maxHeight={`calc(100vh - 32px - 48px - 32px - ${useFooter ? "48px - 16px" : "0px"})`}>
        {typeof title === "string" ? (
          <Heading level="h4" px="400">
            {title}
          </Heading>
        ) : (
          title
        )}
        <StyledContent {...contentProps}>{children}</StyledContent>
      </Box>
      {footer ? (
        <StyledFooter>{footer}</StyledFooter>
      ) : primaryAction || secondaryAction || tertiaryAction || steps ? (
        <Box
          justifyContent={steps ? "space-between" : "flex-end"}
          width="100%"
          flexDirection={type === "STEPS" ? "row" : "row-reverse"}
          mt="400"
          px="400"
          alignItems="center"
          gap="150"
        >
          {steps && (
            <Text preset="medium100" color="textColorSubtle">{`Step: ${steps.current || 1}/${steps.total}`}</Text>
          )}
          <StyledActions type={type}>
            {primaryAction && <Button {...primaryAction} />}
            {secondaryAction && <Button variation="secondary" {...secondaryAction} />}
            {tertiaryAction && <Button variation="secondary" {...tertiaryAction} />}
          </StyledActions>
        </Box>
      ) : null}
      {onHide && <StyledCloseButton variation="flat" icon={<Icon name="XCloseIcon" />} onClick={onHide} />}
    </StyledModal>
  );
};

const Modal = ({ visible, children, overlayProps, onHide, ...props }: ModalProps) => {
  const nodeRef = useRef(null);
  const [isVisible, setIsVisible] = useState(visible);

  const handleHide = onHide ? () => setIsVisible(false) : undefined;

  useEffect(() => {
    const closeOnEscapeKey = ({ key }: KeyboardEvent) => key === "Escape" && handleHide?.();

    document.body.addEventListener("keydown", closeOnEscapeKey);

    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
    // context(alexandrchebotar, 2023-12-11): ignore `handleHide` change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  return (
    <ReactPortal>
      <CSSTransition
        in={isVisible}
        timeout={250}
        unmountOnExit
        mountOnEnter
        appear
        classNames="modal"
        onExited={onHide}
      >
        <StyledOverlay {...overlayProps} ref={nodeRef}>
          <ModalContent visible={isVisible} onHide={handleHide} {...props}>
            {children}
          </ModalContent>
        </StyledOverlay>
      </CSSTransition>
    </ReactPortal>
  );
};

Modal.displayName = "Modal";

export { Modal };
