import styled from "styled-components";
import { color, space, typography } from "styled-system";

import * as tokens from "ds/Core";

import { HeadingStyleProps, ParagraphProps, TextStyleProps } from "./types";

const { TextDecorationUnderline } = tokens;

const disabledForwardProps = [
  "underlined",
  "align",
  "fontWeight",
  "fontSize",
  "display",
  "textDecoration",
  "fontFamily",
];

export const StyledTypography = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !disabledForwardProps.includes(prop) && defaultValidatorFn(prop),
})<TextStyleProps | HeadingStyleProps | ParagraphProps>`
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  ${({ underlined }) => underlined && `text-decoration: ${TextDecorationUnderline};`}
  ${typography}
  ${color}
  ${space}
  ${({ textOverflow }) => textOverflow && `text-overflow: ${textOverflow}`};
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace}`};
  ${({ wordBreak }) => wordBreak && `word-break: ${wordBreak}`};
  ${({ wordWrap }) => wordWrap && `word-wrap: ${wordWrap}`};
  ${({ width }) => width && `width: ${width}`};
  ${({ height }) => height && `height: ${height}`};
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}`};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}`};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}`};
  ${({ display }) => display && `display: ${display}`};
`;
