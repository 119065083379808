import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "ds/Modal";
import { Heading, Text } from "ds/Typography";
import { useDeleteNotebookMutation } from "graphql/build";
import { useGlobalContext, useNavigation } from "hooks";
import { useModalContext } from "hooks/useModalContext";
import { useSelectionContext } from "hooks/useSelectionContext";
import { notify } from "lib/notification";

export const DeleteJourneyModal = () => {
  const { numberUuid } = useGlobalContext();
  const { deleteJourneys, updateModal } = useModalContext();
  const { selectedItems, selectedContent, setSelectedItems, setSelectedContent } = useSelectionContext();
  const { navigateTo } = useNavigation();
  const { t } = useTranslation();

  const [deleteNotebook, { loading: deleteNotebookLoading }] = useDeleteNotebookMutation({
    refetchQueries: ["listStackContainersPreviews"],
    onError(error) {
      notify({
        type: "error",
        position: "bottom-right",
        toastId: "deleteJourneyError",
        updateId: "deleteJourneyError",
        title: error.message.includes("parent_stack_containers")
          ? t("content.stack-containers.notifications.stack-referenced-to-other")
          : error.message,
        autoClose: 5000,
      });
    },
  });

  const onDeleteNotebook = () => {
    if (selectedContent?.uuid) {
      deleteNotebook({
        variables: {
          numberUuid,
          stackContainerUuid: selectedContent.uuid,
        },
      }).then(({ data }) => {
        setSelectedItems(selectedItems?.filter((item) => item === selectedContent.uuid));
        setSelectedContent(undefined);
        updateModal("deleteJourneys", false);

        if (data) {
          notify({
            position: "bottom-right",
            toastId: "deleteJourney",
            updateId: "deleteJourney",
            title: t("content.stack-containers.notifications.stack-deleted", {
              stack: (data?.deleteNotebook?.name || "Stack").split(" - Deleted at ")[0],
            }),
            autoClose: 5000,
          });
          navigateTo("stacks")();
        }
      });
    } else if (selectedItems?.length) {
      Promise.all(
        selectedItems?.map((stackContainerUuid) =>
          deleteNotebook({
            variables: {
              numberUuid,
              stackContainerUuid,
            },
          })
        )
      ).then((data) => {
        setSelectedItems(undefined);
        updateModal("deleteJourneys", false);

        const deletedJourneys = data.filter((i) => i.data);
        if (deletedJourneys.length) {
          notify({
            position: "bottom-right",
            toastId: "deleteJourney",
            updateId: "deleteJourney",
            title: t("content.stack-containers.delete.many-deleted", { count: deletedJourneys.length }),
            autoClose: 5000,
          });
          navigateTo("stacks")();
        }
      });
    }
  };

  const onHide = () => {
    setSelectedContent(undefined);
    updateModal("deleteJourneys", false);
  };

  return (
    <Modal
      type="STEPS"
      visible={deleteJourneys}
      title={
        <Heading level="h4" maxWidth="400px" px="400">
          {!selectedContent
            ? t("content.stack-containers.delete.multiple", { count: selectedItems?.length })
            : t("content.stack-containers.delete.one", { name: selectedContent.name })}
        </Heading>
      }
      onHide={onHide}
      style={{ width: "529px" }}
      overlayProps={{ style: { zIndex: 99 } }}
      primaryAction={{
        text: t("btn.delete"),
        destructive: true,
        loading: deleteNotebookLoading,
        onClick: onDeleteNotebook,
      }}
      secondaryAction={{
        text: t("btn.cancel"),
        onClick: onHide,
      }}
    >
      <Text preset="medium100">{t("content.stack-containers.delete.description")}</Text>
    </Modal>
  );
};
