import styled from "styled-components";
import { space } from "styled-system";

import { BorderColorDefault, InteractiveColorActive, InteractiveColorHoverAlt } from "ds/Alias";
import { ColorGray200, Space25, Space100, Space150, Space200, SpaceNone } from "ds/Core";
import { DropPositions } from "ds/Drop/src/types";

import { getDropdownStyles } from "./theme";
import { DropdownSize, DropdownSizes, DropdownStyleProps } from "./types";

export const StyledDropdownItem = styled.a<DropdownStyleProps>`
  align-items: center;
  justify-content: space-between;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5px;
  transition: 0.3s;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    transition: 0.2s;
  }

  ${({ size, variation, disabled }) => getDropdownStyles({ size, variation, disabled })};

  ${({ open }) =>
    open &&
    `
    background-color: ${InteractiveColorActive};
    &:hover {
      background-color: ${InteractiveColorHoverAlt};
    }
  `}

  ${({ hasSecondLevel }) =>
    hasSecondLevel &&
    `
    min-height: 56px;
    padding: ${SpaceNone} ${Space100}
  `}

  ${space}
`;

export const StyledLeftIcon = styled.div<{ size: DropdownSizes; hasLabel?: boolean }>`
  line-height: 0;
  margin-right: ${({ size, hasLabel }) => (hasLabel ? (size === DropdownSize.LARGE ? Space150 : Space200) : Space25)};
  > * {
    ${({ size }) =>
      size === DropdownSize.LARGE
        ? `
      width: 24px; 
      height: 24px;
    `
        : `
      width: 20px;
      height: 20px;
    `}
  }
`;

export const StyledAvatar = styled.div<{ size: DropdownSizes; hasLabel?: boolean }>`
  line-height: 0;
  margin-right: ${({ size, hasLabel }) => (hasLabel ? (size === DropdownSize.LARGE ? Space150 : Space200) : Space25)};
`;

export const StyledRightIcon = styled.div<{ position: DropPositions; hasLabel?: boolean; maxHeight?: number }>`
  margin-left: ${({ hasLabel }) => (hasLabel ? Space200 : Space25)};
  max-height: ${({ maxHeight }) => maxHeight ?? 20}px;
  ${({ position }) =>
    (["bottom-start", "bottom", "bottom-end", "top-start", "top", "top-end", "auto-start", "auto", "auto-end"].includes(
      position
    ) ||
      !position) &&
    "transform: rotate(90deg);"}
`;

export const StyledLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledDropHeader = styled.div<{ hasContent?: boolean; hasFooter?: boolean }>`
  padding: ${Space100};

  ${({ hasContent, hasFooter, style }) =>
    !hasContent && hasFooter && style && !style.borderBottom && `border-bottom: 1px solid ${BorderColorDefault};`}
`;

export const StyledDropContent = styled.div<{ hasHeader?: boolean; hasFooter?: boolean }>`
  padding: ${Space100};

  ${({ hasHeader }) => hasHeader && `border-top: 1px solid ${BorderColorDefault};`}
  ${({ hasFooter }) => hasFooter && `border-bottom: 1px solid ${BorderColorDefault};`}

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: ${ColorGray200};
    display: none;
    border-radius: 32px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${ColorGray200};
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      display: block;
    }
  }
`;

export const StyledDropFooter = styled.div`
  padding: ${Space100};
`;

export const StyledContent = styled.div`
  display: flex;
  align-items: center;
`;
