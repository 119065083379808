import { ReplyType, TReplyType } from "types";

export const saveLastOrganisation = (organisationUuid?: string) => {
  if (organisationUuid) {
    window.localStorage.setItem("lastOrganisationUuid", organisationUuid);
  }
};

export const saveLastNumber = ({ numberUuid, organisationUuid }: { numberUuid: string; organisationUuid: string }) => {
  const item = window.localStorage.getItem("lastOrganisationsNumbers") || "{}";
  const lastOrganisationsNumbers = JSON.parse(item);

  lastOrganisationsNumbers[organisationUuid] = numberUuid;
  window.localStorage.setItem("lastOrganisationUuid", organisationUuid);
  window.localStorage.setItem("lastOrganisationsNumbers", JSON.stringify(lastOrganisationsNumbers));
};

export const getLastNumberUuid = (organisationUuid?: string) => {
  const item = window.localStorage.getItem("lastOrganisationsNumbers") || "{}";
  const lastOrganisationsNumbers = JSON.parse(item) as Record<string, string>;

  return organisationUuid && lastOrganisationsNumbers[organisationUuid];
};

export const getLastOrganisationUuid = () => window.localStorage.getItem("lastOrganisationUuid") ?? undefined;

export const savePinnedChatList = (pinChatList: boolean) => {
  window.localStorage.setItem("pinChatList", pinChatList.toString());
};

export const getPinnedChatList = (): boolean => {
  const item = window.localStorage.getItem("pinChatList") || "false";

  return JSON.parse(item);
};

export const savePlaybooksBannerVisibility = (visibility: boolean) => {
  window.localStorage.setItem("showPlaybooksBanner", visibility.toString());
};

export const saveOrganisationMetaVerificationDoneStatus = (organisationUuid?: string) => {
  if (organisationUuid) {
    const metaVerificationDoneOrganisations: string[] = JSON.parse(
      window.localStorage.getItem("metaVerificationDoneOrganisations") ?? "[]"
    );

    metaVerificationDoneOrganisations.push(organisationUuid);
    window.localStorage.setItem("metaVerificationDoneOrganisations", JSON.stringify(metaVerificationDoneOrganisations));
  }
};

export const getOrganisationMetaVerificationDoneStatus = (organisationUuid?: string) => {
  const metaVerificationDoneOrganisations: string[] = JSON.parse(
    window.localStorage.getItem("metaVerificationDoneOrganisations") ?? "[]"
  );

  return metaVerificationDoneOrganisations.some((uuid) => uuid === organisationUuid);
};

export const clearMetaVerificationDoneStatuses = () => {
  window.localStorage.removeItem("metaVerificationDoneOrganisations");
};

export const saveReplyType = (replyType: string) => window.localStorage.setItem("replyType", replyType);

export const getReplyType = () => {
  const isValidReplyType = (replyType?: string | null): replyType is TReplyType =>
    Object.values(ReplyType).includes(replyType as TReplyType);

  const storedReplyType = window.localStorage.getItem("replyType");

  return isValidReplyType(storedReplyType) ? storedReplyType : ReplyType.HANDOVER;
};
export const saveStorageSwitch = (key: string, value: boolean) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};
const falseValues = ["false", "undefined", "null", "0", "-"];

export const getStorageSwitch = (key: string) => {
  const item = window.localStorage.getItem(key) ?? "false";
  return !falseValues.includes(item);
};
