import styled from "styled-components";
import { layout, space } from "styled-system";

import {
  FeedbackColorBackgroundCritical,
  FeedbackColorBackgroundInformative,
  FeedbackColorBackgroundSuccess,
  FeedbackColorBackgroundWarning,
} from "ds/Alias";
import { SpaceNone } from "ds/Core";
import {
  FormBorderRadiusOptional,
  FormColorBackgroundOptional,
  FormColorTextHint,
  FormColorTextLabel,
  FormColorTextOptional,
  FormColorTextValidation,
  FormSpaceLabelInline,
  FormSpaceLabelStack,
  FormSpaceLargeGap,
  FormSpaceOptionalLabelBottom,
  FormSpaceOptionalLabelLeft,
  FormSpaceOptionalLabelRight,
  FormSpaceOptionalLabelTop,
  FormSpaceValidationHintBottom,
  FormSpaceValidationHintLeft,
  FormSpaceValidationHintRight,
  FormSpaceValidationHintTop,
  FormTextHint,
  FormTextLabel,
  FormTextOptional,
  FormTextValidation,
} from "ds/Form";
import { StatusType, StatusTypes } from "ds/types/status";

export const FormItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${FormSpaceLargeGap};
  width: 100%;
  box-sizing: border-box;

  ${space}
  ${layout}
`;

export const FormItemContent = styled.div<{ withStatus?: boolean }>`
  position: relative;
  width: 100%;
`;

export const FormItemStatusMessage = styled.div<{ status?: StatusTypes }>`
  padding: ${FormSpaceValidationHintTop} ${FormSpaceValidationHintRight} ${FormSpaceValidationHintBottom}
    ${FormSpaceValidationHintLeft};
  font-family: ${FormTextValidation.fontFamily};
  font-size: ${FormTextValidation.fontSize};
  line-height: ${FormTextValidation.lineHeight};
  color: ${FormColorTextValidation};
  background: ${({ status }) =>
    status === StatusType.ERROR
      ? FeedbackColorBackgroundCritical
      : status === StatusType.SUCCESS
        ? FeedbackColorBackgroundSuccess
        : status === StatusType.WARNING
          ? FeedbackColorBackgroundWarning
          : FeedbackColorBackgroundInformative};
  border-radius: 4px;
`;

export const FormItemLabelContainer = styled.div`
  display: flex;
  gap: ${FormSpaceLabelInline};
  margin-bottom: ${FormSpaceLabelStack};
  align-items: center;
  justify-content: space-between;

  &:last-child {
    margin-bottom: ${SpaceNone};
  }
`;

export const FormItemLabel = styled.span`
  font-family: ${FormTextLabel.fontFamily};
  font-size: ${FormTextLabel.fontSize};
  font-weight: ${FormTextLabel.fontWeight};
  line-height: ${FormTextLabel.lineHeight};
  color: ${FormColorTextLabel};
`;

export const FormItemLabelTag = styled.div<{ error?: boolean }>`
  padding: ${FormSpaceOptionalLabelTop} ${FormSpaceOptionalLabelRight} ${FormSpaceOptionalLabelBottom}
    ${FormSpaceOptionalLabelLeft};
  font-family: ${FormTextOptional.fontFamily};
  font-size: ${FormTextOptional.fontSize};
  line-height: ${FormTextOptional.lineHeight};
  color: ${FormColorTextOptional};
  background: ${FormColorBackgroundOptional};
  border-radius: ${FormBorderRadiusOptional};
`;

export const FormItemHint = styled.div`
  font-family: ${FormTextHint.fontFamily};
  font-size: ${FormTextHint.fontSize};
  line-height: ${FormTextHint.lineHeight};
  color: ${FormColorTextHint};
`;

export const FormLabel = styled.label`
  width: 100%;
`;
