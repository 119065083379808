import React from "react";

import { ColorBrandDefault, ColorNeutralWhite } from "ds/Core";

import { StyledLogo, StyledWordMark } from "./Styles";
import { TurnLogo, TurnWordMark } from "./TurnLogos";
import { LogoAs, LogoProps, LogoTypes } from "./types";

const Logo = React.forwardRef<HTMLDivElement, LogoProps>(
  ({ type = LogoTypes.DEFAULT, as = LogoAs.AVATAR, size = 50, ...props }, ref) => {
    const avatar = (
      <TurnLogo fill={type === LogoTypes.DEFAULT && as !== LogoAs.WORDMARK ? ColorNeutralWhite : ColorBrandDefault} />
    );
    const wordMark = (
      <TurnWordMark
        fill={type === LogoTypes.DEFAULT && as !== LogoAs.WORDMARK ? ColorNeutralWhite : ColorBrandDefault}
      />
    );

    if (as === LogoAs.WORDMARK) {
      return (
        <StyledWordMark size={size} {...props}>
          {wordMark}
        </StyledWordMark>
      );
    }

    return (
      <StyledLogo ref={ref} type={type} size={size} {...props}>
        {as === LogoAs.AVATAR ? avatar : wordMark}
      </StyledLogo>
    );
  }
);

Logo.displayName = "Logo";

export { Logo };
