import React from "react";

import { Text } from "ds/Typography";
import { Icon } from "ds/icons";

import { StyledSpinner, StyledSpinnerContainer } from "./styles";
import { ISpinner, SpinnerSize } from "./types";

const sizes: { [key in SpinnerSize]: string } = {
  small: "24px",
  big: "30px",
};

export const Spinner = ({ color, size = "big", speed = 1, text, children, ...rest }: ISpinner) => {
  const sizeValue = sizes[size] ?? size;

  return (
    <StyledSpinnerContainer {...rest}>
      <StyledSpinner size={sizeValue} speed={speed}>
        {children ?? <Icon name="SpinnerIcon" size={sizeValue} color={color} />}
      </StyledSpinner>
      {text && <Text preset="paragraph1-100">{text}</Text>}
    </StyledSpinnerContainer>
  );
};

// todo(alexandrchebotar, 2022-10-31): docs
