import React, { createContext, useState } from "react";

import { BannerStatus } from "ds/Banner/src/types";
import { SubscriptionState, SwitchTargetPrice } from "graphql/generated/types";
import { PaymentNumberSubscriptionDetailsFragment } from "graphql/subscriptions";

export enum PaymentBannerStatus {
  "ACTIVE_DATE" = "ACTIVE_DATE",
  "PAST_DUE" = "PAST_DUE",
  "PAUSED" = "PAUSED",
  "PAUSE_DATE" = "PAUSED_DATE",
  "PAUSE_HOURS" = "PAUSED_HOURS",
  "CANCELED" = "CANCELED",
  "CANCEL_DATE" = "CANCEL_DATE",
  "CANCEL_HOURS" = "CANCEL_HOURS",
  "TRIALING" = "TRIALING",
  "INACTIVE" = "INACTIVE",
}

export type PaymentBannerStatusState = {
  status: PaymentBannerStatus;
  days?: number;
  hours?: number;
};

interface PaymentState {
  state?: `${BannerStatus}`;
  switchRequestAlreadySubmitted?: boolean;
  subscription?: PaymentNumberSubscriptionDetailsFragment;
  status?: PaymentBannerStatusState;
}
type SelectedPriceType = {
  targetPrice: SwitchTargetPrice;
  _ptxn: string;
  sellerId: number;
};

export enum PaymentStatus {
  CHOOSE_PLAN = "CHOOSE_PLAN",
  CHECKOUT = "CHECKOUT",
  SUCCESS_PRO = "SUCCESS_PRO",
  SUCCESS_ENTERPRISE = "SUCCESS_ENTERPRISE",
}

export type PaymentTypes = keyof PaymentState;

export interface PaymentContext extends PaymentState {
  setPaymentState: React.Dispatch<React.SetStateAction<PaymentState>>;
  selectedPrice?: SelectedPriceType;
  setSelectedPrice: React.Dispatch<React.SetStateAction<SelectedPriceType | undefined>>;
  paymentStatus?: PaymentStatus;
  setPaymentStatus: React.Dispatch<React.SetStateAction<PaymentStatus | undefined>>;
  chargePastDueOnAutomatic: boolean;
  disabledSubscription?: boolean;
  setDisabledSubscription: React.Dispatch<React.SetStateAction<boolean>>;
  setChargePastDueOnAutomatic: React.Dispatch<React.SetStateAction<boolean>>;
  exemptedNumber: boolean;
  setExemptedNumber: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PaymentContext = createContext<PaymentContext>({
  setPaymentState: () => {},
  setSelectedPrice: () => {},
  setPaymentStatus: () => {},
  setDisabledSubscription: () => {},
  chargePastDueOnAutomatic: false,
  setChargePastDueOnAutomatic: () => {},
  exemptedNumber: false,
  setExemptedNumber: () => {},
});

interface PaymentProviderProps {
  children: React.ReactNode;
}
export const PaymentProvider = ({ children }: PaymentProviderProps) => {
  const [state, setState] = useState<PaymentState>({
    state: undefined,
    switchRequestAlreadySubmitted: undefined,
    subscription: undefined,
    status: undefined,
  });

  const [selectedPrice, setSelectedPrice] = useState<SelectedPriceType | undefined>();

  const [status, setStatus] = useState<PaymentStatus | undefined>(PaymentStatus.CHOOSE_PLAN);

  const [chargePastDueOnAutomatic, setChargePastDueOnAutomatic] = useState(false);

  const [exemptedNumber, setExemptedNumber] = useState(false);
  const [disabledSubscription, setPaymentDisabled] = useState(false);

  return (
    <PaymentContext.Provider
      value={{
        ...state,
        selectedPrice,
        paymentStatus: status,
        chargePastDueOnAutomatic,
        setDisabledSubscription: setPaymentDisabled,
        disabledSubscription:
          disabledSubscription &&
          (exemptedNumber
            ? false
            : state.subscription?.state === SubscriptionState.CANCELED ||
              state.subscription?.state === SubscriptionState.PAUSED),
        setPaymentState: setState,
        setSelectedPrice: setSelectedPrice,
        setPaymentStatus: setStatus,
        setChargePastDueOnAutomatic,
        exemptedNumber,
        setExemptedNumber,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
