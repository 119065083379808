import { Box } from "grommet";
import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from "react-i18next";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import styled from "styled-components";

import { Button } from "ds/Button";
import { Icon } from "ds/icons";
import { down } from "ds/theme";
import { useNavigation } from "hooks";
import { useLayoutContext } from "hooks/useLayoutContext";
import { ConfirmCard } from "lib/confirm";
import { Anchor, Paragraph, Text, getSpacing } from "lib/impact";
import { INBOX_COLUMN_WIDTH } from "pages/NumberDetail/pages/Inbox/styles";
import { turnTheme } from "theme";
import { TurnSupportSignUp } from "utils/links";

import { Footer } from "../components/Layout";
import { StatusColors, StepColors } from "../styles";
import { OnboardingStep, StepStatuses } from "../types";
import { formatPhoneNumber } from "../utils";
import { StatusTag } from "./StatusTag";
import { StepStatusCard, StepSubTitle, StepText } from "./StepStatusCard";

const OnboardingProgressContainer = styled(Box)<{
  sidebar?: boolean;
  offset: string;
}>`
  width: ${INBOX_COLUMN_WIDTH};
  background: ${turnTheme.global.colors.white};
  ${({ sidebar, offset }) =>
    sidebar
      ? `
          position: fixed;
          top: calc(${offset} + 64px);
          right: 0;
          z-index: 1;
          height: calc(100vh - ${offset} - 64px);
        `
      : `
          border-radius: 10px;
        `}
  ${down("lg")} {
    width: 100%;
    height: 100%;
    position: relative;
    top: ${({ offset }) => offset};
    right: 0px;
  }
`;
const SidebarHeading = styled(Text)`
  font-size: 24px;
  line-height: 1.2;
  font-weight: 500;
  padding: ${getSpacing("m")};

  @media screen and (max-height: 830px) {
    font-size: 20px;
    padding: ${getSpacing("s")};
  }
`;

interface IStatusProps {
  label: string;
  color: StatusColors;
  tooltip?: {
    id: string;
    i18nKey:
      | "onboarding.progress-bar.tooltip.contact-facebook"
      | "onboarding.progress-bar.tooltip.not-verified"
      | "onboarding.progress-bar.tooltip.number-pending";
    href?: string;
  };
}

interface IOnboardingProgressContent {
  accountStatus: IStatusProps;
  businessStatus: IStatusProps;
  cancelOnboardingApplication: () => void;
  embeddedSignupStatus: StepStatuses;
  isAdmin: boolean;
  nameStatus: IStatusProps;
  numberStatus: StepStatuses;
  organisationHasDirectNumber: boolean;
  payStatus: StepStatuses;
  phoneNumber?: string | null;
  sidebar?: boolean;
  verificationStatus: StepStatuses;
  onClose?: () => void;
  hideHeader?: boolean;
}

export const OnboardingProgressContent = ({
  accountStatus,
  businessStatus,
  cancelOnboardingApplication,
  embeddedSignupStatus,
  isAdmin,
  nameStatus,
  numberStatus,
  organisationHasDirectNumber,
  payStatus,
  phoneNumber,
  sidebar,
  verificationStatus,
  onClose,
  hideHeader,
}: IOnboardingProgressContent) => {
  const { offset } = useLayoutContext();
  const isMobile = useBreakpoint(down("lg"));
  const { t } = useTranslation();
  const { navigateTo } = useNavigation();
  const onNext = (step: OnboardingStep) => (sidebar ? navigateTo("onboarding", { state: { step } }) : undefined);

  const cancelOnboarding = () => {
    cancelOnboardingApplication();
    navigateTo("/")();
  };
  const showConfirmModal = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmCard
          onClose={onClose}
          onConfirm={cancelOnboarding}
          btnConfirmTitle={t("onboarding.progress-bar.cancel-onboarding.confirm")}
          btnCancelTitle={t("onboarding.progress-bar.cancel-onboarding.cancel")}
          alertMessage={t("onboarding.progress-bar.cancel-onboarding.alert")}
          title={t("onboarding.progress-bar.cancel-onboarding.alert-title")}
          hideCloseBtn
        />
      ),
    });
  };

  return (
    <OnboardingProgressContainer justify="between" sidebar={sidebar} offset={offset}>
      <Box
        pad={{ horizontal: getSpacing("s"), vertical: sidebar ? getSpacing("s") : "0px" }}
        margin={{ vertical: sidebar ? "0px" : getSpacing("s") }}
        overflow="auto"
      >
        {!hideHeader && (
          <Box direction="row" align="center" justify="between" margin={{ bottom: isMobile ? "24px" : "s" }}>
            <SidebarHeading>{t("onboarding.progress-bar.heading")}</SidebarHeading>
            {isMobile && <Button variation="flat" onClick={onClose} icon={<Icon name="XCloseIcon" />} size="small" />}
          </Box>
        )}
        <Box gap={getSpacing("s")}>
          <StepStatusCard
            title={t("onboarding.progress-bar.number")}
            color={StepColors.NUMBER}
            status={numberStatus}
            onNext={onNext(OnboardingStep.NUMBER)}
            isAdmin={isAdmin}
          >
            {numberStatus === StepStatuses.PENDING && (
              <StatusTag
                label={t("onboarding.progress-bar.status.in-progress")}
                color={StatusColors.PENDING}
                tooltip={{
                  id: "onboarding-number",
                  i18nKey: "onboarding.progress-bar.tooltip.number-pending",
                }}
              />
            )}
            {numberStatus === StepStatuses.DONE && (
              <StepText className="ph-no-capture">{formatPhoneNumber(phoneNumber)}</StepText>
            )}
          </StepStatusCard>
          <StepStatusCard
            title={t("onboarding.progress-bar.embedded-signup.title")}
            color={StepColors.EMBEDDED_SIGNUP}
            status={embeddedSignupStatus}
            onNext={onNext(OnboardingStep.EMBEDDED_SIGNUP)}
            isAdmin={isAdmin}
          >
            {embeddedSignupStatus === StepStatuses.DONE && (
              <Box pad={{ bottom: getSpacing("s") }} gap={getSpacing("s")}>
                <StepSubTitle size="lmedium" margin={{ top: "m", bottom: "s" }}>
                  {t("onboarding.progress-bar.embedded-signup.business-account")}
                </StepSubTitle>
                <StatusTag {...accountStatus} />
                <StepSubTitle size="lmedium" margin={{ top: "ml", bottom: "s" }}>
                  {t("onboarding.progress-bar.embedded-signup.display-name")}
                </StepSubTitle>
                <StatusTag {...nameStatus} />
              </Box>
            )}
          </StepStatusCard>
          <StepStatusCard
            title={t("onboarding.progress-bar.business-verification")}
            color={StepColors.META_VERIFICATION}
            status={verificationStatus}
            onNext={onNext(OnboardingStep.META_VERIFICATION)}
            isAdmin={isAdmin}
          >
            {verificationStatus === StepStatuses.DONE && (
              <Box pad={{ bottom: getSpacing("s") }}>
                <StatusTag {...businessStatus} />
              </Box>
            )}
          </StepStatusCard>
          <StepStatusCard
            title={t("onboarding.progress-bar.choose-plan")}
            color={StepColors.CHOOSE_PLAN}
            status={payStatus}
            onNext={onNext(OnboardingStep.CHOOSE_PLAN)}
            isAdmin={isAdmin}
          />
          {organisationHasDirectNumber && embeddedSignupStatus !== StepStatuses.DONE && (
            <Anchor
              onClick={sidebar ? showConfirmModal : cancelOnboarding}
              size="lmedium"
              margin={{ vertical: "none", horizontal: "m" }}
              padding={{ bottom: "s" }}
              decoration="underline"
              style={{ textAlign: "center" }}
            >
              {t("onboarding.progress-bar.cancel-onboarding.button")}
            </Anchor>
          )}
          {[numberStatus, embeddedSignupStatus, verificationStatus, payStatus].every(
            (status) => status === StepStatuses.DONE
          ) && (
            <Paragraph size="medium" margin={{ vertical: "none", horizontal: "m" }} padding={{ bottom: "s" }}>
              {t("onboarding.progress-bar.no-more-steps")}
            </Paragraph>
          )}
        </Box>
      </Box>
      {sidebar && <Footer label={t("global.support")} href={TurnSupportSignUp} />}
    </OnboardingProgressContainer>
  );
};
