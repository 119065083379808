import styled from "styled-components";

import { InteractiveColorActive, InteractiveColorHoverAlt } from "ds/Alias";
import { Box } from "ds/Box";
import {
  BorderRadius100,
  BorderRadius200,
  ColorBrandAccent,
  ColorBrandDefault,
  ColorGray100,
  ColorGray700,
  ColorLavender100,
  ColorNeutralWhite,
  Space25,
  Space50,
  Space100,
  Space200,
  SpaceNone,
} from "ds/Core";

import { NavItemProps, NavType, NavsProps, NavsType, TNavType } from "./types";

const NAV_HEIGHT = "40px";
export const ICON_NAV_SIZE = NAV_HEIGHT;

export const StyledNavs = styled.ul<Omit<NavsProps, "items">>`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: ${Space50};
  flex-direction: ${({ type }) => (type === NavsType.HORIZONTAL ? "row" : "column")};
  justify-content: space-between;
  transition: all 150ms ease-out;
`;

export const StyledNav = styled.li<Omit<NavItemProps, "text">>`
  border-radius: ${BorderRadius200};

  // todo(alexandrchebotar, 2023-10-12): add feature type styling when it's needed
  & > a {
    padding: ${({ type }) => (type === NavType.ICON ? SpaceNone : `${SpaceNone} ${Space200} ${SpaceNone} ${Space100}`)};
    height: ${NAV_HEIGHT};
    width: ${({ type }) => (type === NavType.ICON ? ICON_NAV_SIZE : "auto")};
    border-radius: ${BorderRadius200};
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: ${ColorBrandDefault};
    justify-content: ${({ type }) => (type === NavType.ICON ? "center" : "flex-start")};
  }

  &:hover {
    background-color: ${InteractiveColorHoverAlt};
  }
  &:hover .counter-box {
    background-color: ${ColorNeutralWhite};
  }

  ${({ current }) =>
    current &&
    `
    background-color: ${InteractiveColorActive};
    `}
`;

export const StyledVersion = styled.span`
  padding: ${Space25} ${Space50};
  height: 22px;
  background: ${ColorBrandDefault};
  border-radius: ${BorderRadius100};

  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const StyledCountBox = styled(Box)<{ type: TNavType; unread?: boolean }>`
  width: ${({ type }) => (type === NavType.ICON ? "16px" : "20px")};
  height: ${({ type }) => (type === NavType.ICON ? "16px" : "20px")};
  min-width: max-content;
  padding: ${({ type }) => (type === NavType.ICON ? "1px" : "2px")};
  color: ${({ unread }) => (unread ? ColorBrandAccent : ColorGray700)};
  background-color: ${({ type, unread }) =>
    unread ? ColorLavender100 : type === NavType.ICON ? ColorGray100 : "transparent"};
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  margin-left: auto;
  position: absolute;
  overflow: hidden;
  top: ${({ type }) => (type === NavType.ICON ? "2px" : "10px")};
  right: ${({ type }) => (type === NavType.ICON ? "2px" : "12px")};
`;

export const StyledTextContainer = styled(Box)<{ type: TNavType }>`
  display: ${({ type }) => (type === NavType.ICON ? "none" : "flex")};
  flex-direction: row;
  gap: 5px;
  align-items: center;
  visibility: hidden;
  animation-name: ${({ type }) => (type === NavType.ICON ? undefined : "showText")};
  animation-duration: 120ms;
  animation-delay: 100ms;
  animation-fill-mode: forwards;
  opacity: ${({ type }) => (type === NavType.ICON ? 1 : 0)};

  @keyframes showText {
    50% {
      display: flex;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }
`;
