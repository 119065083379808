import { OffsetsFunction } from "@popperjs/core/lib/modifiers/offset";
// import { useOuterClick } from "ds/utils/useOuterClick";
import React, { useEffect, useState } from "react";
import { usePopper } from "react-popper";

import { Space500 } from "ds/Core";
import { ReactPortal } from "ds/Modal/src/Portal";
import { Spinner } from "ds/Spinner";
import { useClickOutside } from "hooks";

import { StyledDrop, StyledDropdownContainer } from "./styles";
import { DropProps } from "./types";

const offset = 8;
const levelOffsetFunction: OffsetsFunction = ({ placement }) => {
  switch (placement) {
    case "top-start":
      return [-offset, offset];
    case "right-end":
    case "left-end":
      return [offset, offset * 2];
    default:
      return [-offset, offset * 2];
  }
};

const WithPortal = ({ children, usePortal }: { usePortal?: boolean; children: React.ReactNode }) => {
  if (usePortal) {
    return <ReactPortal wrapperId="drop-root">{children}</ReactPortal>;
  }
  return <>{children}</>;
};

const Drop = ({
  children,
  disabled,
  disableOutsideClick,
  dropProps = {},
  loading,
  loadingText,
  onHide,
  onShow,
  position = "bottom-start",
  trigger,
  visible = false,
  isLevelDrop,
  useTriggerWidth,
  popper,
  usePortal = true,
  minWidth = "275px",
  minHeight = "56px",
}: DropProps) => {
  const [open, setOpen] = useState(visible);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: position,
    strategy: "fixed",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: !isLevelDrop ? [0, offset] : levelOffsetFunction,
        },
      },
    ],
    ...popper,
  });

  useEffect(() => {
    setOpen(visible);
  }, [visible]);

  useEffect(() => {
    if (dropProps.updatePosition) {
      update?.();
    }
  }, [dropProps.updatePosition, update]);

  const [containerRef] = useClickOutside(
    () => {
      if (open) {
        setOpen(false);
        onHide?.();
      }
    },
    { ignoreQuery: ["drop-trigger", "drop-content"], skip: disableOutsideClick }
  );

  const onToggle = () => {
    if (!disabled) {
      setOpen(!open);
      if (open) {
        onHide?.();
      } else {
        onShow?.();
      }
    }
  };

  const { style = {}, ...dropRaw } = dropProps;

  return (
    <StyledDropdownContainer ref={containerRef}>
      <StyledDropdownContainer ref={setReferenceElement}>
        {trigger &&
          React.cloneElement(trigger, {
            disabled,
            open,
            onClick: disabled ? undefined : (trigger.props.onClick ?? onToggle),
            className: isLevelDrop
              ? trigger.props.className
                ? `${trigger.props.className} drop-trigger`
                : "drop-trigger"
              : trigger.props.className,
          })}
        <WithPortal usePortal={usePortal}>
          {open && (
            <StyledDrop
              ref={setPopperElement}
              className="drop-content"
              style={{
                ...styles.popper,
                ...style,
              }}
              minWidth={minWidth}
              minHeight={minHeight}
              width={useTriggerWidth ? referenceElement?.clientWidth : "max-content"}
              {...attributes.popper}
              {...dropRaw}
            >
              {loading ? <Spinner margin={Space500} text={loadingText} /> : children}
            </StyledDrop>
          )}
        </WithPortal>
      </StyledDropdownContainer>
    </StyledDropdownContainer>
  );
};

Drop.displayName = "Drop";

export { Drop };
