import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StringParam, useQueryParams } from "use-query-params";

import { DEFAULT_APP_CONFIG } from "_constants";
import { Box } from "ds/Box";
import { Text } from "ds/Typography";
import { FullPageLoader } from "lib/loaders/FullPageLoader";

const { paddle_client_side_token, profitwell_public_token } = window.APP_CONFIG || DEFAULT_APP_CONFIG;

export const Profitwell = () => {
  const { t } = useTranslation();
  const [countdown, setCountdown] = useState(30);
  const [loading, setLoading] = useState(true);
  const [query] = useQueryParams({
    token: StringParam,
  });

  const [paddle, setPaddle] = useState<any>();

  useEffect(() => {
    if (!query.token || query.token !== profitwell_public_token) {
      window.location.replace("/");
    }
  }, [query]);

  useEffect(() => {
    const head = document.querySelector("head");
    const currentScript = document.getElementById("paddle") as HTMLScriptElement | undefined;

    if (window["Paddle"] && currentScript?.src === "https://cdn.paddle.com/paddle/v2/paddle.js") {
      setPaddle(window["Paddle"]);
    } else {
      window["Paddle"] = undefined;
      currentScript?.remove();

      const script = document.createElement("script");
      script.src = "https://cdn.paddle.com/paddle/v2/paddle.js";
      script.async = true;
      script.id = "paddle";
      script.onload = () => setPaddle(window["Paddle"]);

      head?.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (paddle) {
      if (
        process.env.NODE_ENV !== "production" ||
        ["qa.whatsapp.turn.io", "pr.turn.io"].includes(window.location.host)
      ) {
        paddle.Environment.set("sandbox");
      }
      paddle.Setup({
        token: paddle_client_side_token,
        pwAuth: profitwell_public_token,
        eventCallback: (data) => {
          if (data.name === "checkout.loaded" || data.type === "checkout.error") {
            setTimeout(() => setLoading(false), 1000);
          }
        },
      });

      // context(Puvvl, 10-12-2023) Clean loading state in case if eventCallback doesn't return any callback
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  }, [paddle, query]);

  useEffect(() => {
    let timer;
    if (!loading) {
      timer = setTimeout(() => {
        if (countdown > 1) {
          setCountdown(countdown - 1);
        } else {
          window.location.replace("/");
        }
      }, 1000);
    }
    return () => {
      timer && clearInterval(timer);
    };
  }, [countdown, loading]);

  return (
    <Box width="100%" minHeight="100vh" justifyContent="center" alignItems="center">
      {loading ? (
        <FullPageLoader cover title={t("profitwell.loading")} />
      ) : (
        <>
          <Text preset="paragraph2-200">{t("profitwell.loaded")}</Text>
          <Text preset="paragraph2-200">{t("profitwell.countdown", { seconds: countdown })}</Text>
        </>
      )}
    </Box>
  );
};
