import React from "react";
import { useTranslation } from "react-i18next";

import { Box } from "ds/Box";
import { Modal } from "ds/Modal";
import { Text } from "ds/Typography";

interface IInfoPortugueseLocaleModal {
  visible: boolean;
  onAcceptPortuguese: () => void;
  onKeepEnglish: () => void;
}

export const InfoPortugueseLocaleModal = ({
  visible,
  onAcceptPortuguese,
  onKeepEnglish,
}: IInfoPortugueseLocaleModal) => {
  const { t } = useTranslation();

  return (
    <Modal
      // context(alexandrchebotar, 2024-12-11): Portuguese copy in this modal should be in certain (Portuguese/English) language. Translations are not used.
      title="🇧🇷 A Turn.io está disponível em Português"
      visible={visible}
      type="STEPS"
      onHide={onKeepEnglish}
      primaryAction={{
        text: "Mudar para Português",
        onClick: onAcceptPortuguese,
      }}
      secondaryAction={{
        variation: "primary",
        text: t("modals.info-portuguese-locale.keep-english"),
        onClick: onKeepEnglish,
      }}
    >
      <Box width="472px" gap="100">
        {t("modals.info-portuguese-locale.portuguese-available")}
        <Text color="textColorNonessential"> {t("modals.info-portuguese-locale.you-can-switch-back")}</Text>
      </Box>
    </Modal>
  );
};
