import { between, createTheme, down, only, up } from "styled-breakpoints";
import { CSSProperties } from "styled-components";
import { styleFn } from "styled-system";

import { SizeUnitType } from "ds/types/layout";

import { AliasIconSizeType, aliasTokens } from "./alias-tokens";
import { CoreSpaceType, coreTokens } from "./core-tokens";

export const breakpoints = {
  sm: "0px",
  md: "744px",
  lg: "1133px",
  xl: "1280px",
  xxl: "1440px",
};

const StyledBreakpointsTheme = createTheme(breakpoints);

export const colors = {
  ...coreTokens.color,
  ...aliasTokens.color,
};

export const sizes = {
  ...coreTokens.size,
  ...aliasTokens.iconSize,
};

export const defaultTheme = {
  space: coreTokens.space,
  sizes,
  background: colors,
  borderWidths: coreTokens.borderWidth,
  borderColors: colors,
  radii: coreTokens.borderRadius,
  colors,
  fonts: coreTokens.fontFamily,
  fontSizes: coreTokens.fontSize,
  fontWeights: coreTokens.fontWeight,
  lineHeights: coreTokens.lineHeight,
  shadows: coreTokens.shadow,
  gap: coreTokens.size,
  breakpoints,
  ...StyledBreakpointsTheme,
};

export const size: styleFn = ({ size }: { size: AliasIconSizeType | SizeUnitType }) => {
  const value = defaultTheme.sizes[size] ?? `${size}`;
  return `width: ${value}; height: ${value};`;
};

// todo(alexandrchebotar, 2023-11-17): add responsive styles parsing
export const gap: styleFn = ({ gap }: { gap: CoreSpaceType | SizeUnitType }) =>
  gap && `gap: ${defaultTheme.space[gap] ?? gap};`;
export const transition: styleFn = ({ transition }: { transition: CSSProperties["transition"] }) =>
  transition && `transition: ${transition};`;
export const cursor: styleFn = ({ cursor }: { cursor: CSSProperties["cursor"] }) => cursor && `cursor: ${cursor};`;

export type ThemeType = typeof defaultTheme;

export { down, up, between, only };
