import { Anchor, Box, Text } from "grommet";
import React from "react";

import { FeedbackColorCritical } from "ds/Alias";
import { turnTheme } from "theme";

const SUCCESS_TYPE = "success";
const ALERT_TYPE = "alert";
const ERROR_TYPE = "error";

export const TextMessage = ({ type, label, text, href, ...rest }) => {
  const getMessageTypeProps = () => {
    switch (type) {
      case ERROR_TYPE:
        return {
          color: FeedbackColorCritical,
          background: turnTheme.global.colors["label-1-tint"],
        };
      case ALERT_TYPE:
        return {
          color: turnTheme.global.colors["label-2"],
          background: turnTheme.global.colors["label-2-tint"],
        };
      case SUCCESS_TYPE:
        return {
          color: turnTheme.global.colors["label-9"],
          background: turnTheme.global.colors["label-9-tint"],
        };
      default:
        return {
          color: turnTheme.global.colors["label-3"],
          background: turnTheme.global.colors["label-3-tint"],
        };
    }
  };
  const { color, background } = getMessageTypeProps();
  return (
    <Box pad={{ vertical: "4px", horizontal: "16px" }} round="5px" background={background} {...rest}>
      <Text color={color}>
        <>
          {label ? `${label}: ` : ""}
          {href ? <Anchor color={color} href={href} label={text} target="_blank" /> : { text }}
        </>
      </Text>
    </Box>
  );
};
