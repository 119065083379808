import React from "react";

import { typographyTokens } from "ds/theme/typography-tokens";

import { StyledTypography } from "./Styles";
import { HeadingProps } from "./types";

const Heading = React.forwardRef<HTMLHeadingElement, HeadingProps>(({ children, level = "h1", ...rest }, ref) => (
  <StyledTypography {...typographyTokens.headings[level]} ref={ref} {...rest} as={level}>
    {children}
  </StyledTypography>
));

Heading.displayName = "Heading";

export { Heading };
