import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { LocaleContext } from "./LocaleProvider";

export const useLocaleContext = () => {
  const location = useLocation();
  const { onLocationChanged, ...restContext } = useContext(LocaleContext);

  // context(alexandrchebotar, 2024-12-11): This effect is needed to re-run language detecting if the user navigates from the page with disabled autodetecting (like Login)
  // We need to run effect here because LocaleProvider is mounted before the Router root
  useEffect(() => {
    onLocationChanged();
  }, [location.pathname, onLocationChanged]);

  return { ...restContext };
};
