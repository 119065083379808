import _isFunction from "lodash/isFunction";
import React, { useRef, useState } from "react";

import { Icon } from "ds/icons";

import { StyledClearButton, StyledIconLeft, StyledIconRight, StyledInput, StyledInputContainer } from "./styles";
import { TextInputProps } from "./types";

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      error,
      flat,
      icon,
      iconRight,
      onChange,
      onClick,
      placeholder,
      type = "text",
      value,
      size = "medium",
      containerProps,
      disabled,
      allowClear,
      ...rest
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>();
    const [innerValue, setInnerValue] = useState(value);

    const setRef = (element: HTMLInputElement) => {
      inputRef.current = element;
      if (_isFunction(ref)) {
        ref(element);
      } else if (ref) {
        ref.current = element;
      }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInnerValue(e.target.value);
      onChange?.(e);
    };

    const handleClear = () => {
      if (!disabled && inputRef.current) {
        inputRef.current.value = "";
        setInnerValue(undefined);
        onChange?.({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>);
      }
    };

    const search = type === "search";

    const onClickIcon = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      if (onClick && !disabled) {
        inputRef?.current?.focus();
        onClick(event);
      }
    };

    return (
      <StyledInputContainer inputSize={size} {...containerProps}>
        {(icon || search) && (
          <StyledIconLeft inputSize={size} flat={flat}>
            {icon ?? <Icon name="SearchSmIcon" />}
          </StyledIconLeft>
        )}
        <StyledInput
          error={error}
          flat={flat}
          iconLeft={Boolean(icon || search)}
          iconRight={Boolean(iconRight || search || allowClear)}
          inputSize={size}
          onChange={handleChange}
          placeholder={placeholder}
          ref={setRef}
          type={type}
          value={value}
          onClick={onClick}
          disabled={disabled}
          dir="auto"
          {...rest}
        />
        {(search && innerValue) || (allowClear && (innerValue || value)) ? (
          <StyledClearButton
            variation="flat"
            size={size === "small" ? "xsmall" : "small"}
            icon={<Icon name="XCloseIcon" />}
            onClick={handleClear}
            inputSize={size}
            flat={flat}
            disabled={disabled}
          />
        ) : iconRight ? (
          <StyledIconRight aria-disabled={disabled} inputSize={size} onClick={onClickIcon}>
            {iconRight}
          </StyledIconRight>
        ) : null}
      </StyledInputContainer>
    );
  }
);
TextInput.displayName = "TextInput";

export { TextInput };

/* todo(alexandrchebotar, 2022-10-30):
        1. Types
        2. Add docs
*/
