import React from "react";
import { useTranslation } from "react-i18next";
import { down } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";

import { BorderColorDefault } from "ds/Alias";
import { Box } from "ds/Box";
import { Breadcrumbs } from "ds/Breadcrumbs";
import { Button } from "ds/Button";
import { Dropdown } from "ds/Dropdown";
import { DropdownSize } from "ds/Dropdown/src/types";
import { Logo } from "ds/Logo";
import { Navs } from "ds/Navs";
import { Text } from "ds/Typography";
import { Icon } from "ds/icons";

import { Actions, SavingStatus } from "./components";
import { Filter } from "./components/Filter";
import { Search } from "./components/Search";
import { Sort } from "./components/Sort";
import { Tabs } from "./components/Tabs";
import { StyledCenterNavigation, StyledNavigationContainer, StyledRight, StyledRightNavigation } from "./styles";
import { NavigationHeaderProps, NavigationHeaderVariants } from "./types";

const NavigationHeader = ({
  variant = NavigationHeaderVariants.PRIMARY,
  text,
  logo,
  actions,
  search,
  filter,
  sort,
  help,
  feedback,
  menu,
  tabs,
  breadcrumb,
  centerActions,
  back,
  savingStatus,
  navs,
  ...props
}: NavigationHeaderProps) => {
  const { t } = useTranslation();

  const isDownLg = useBreakpoint(down("lg"));
  const isDownMd = useBreakpoint(down("md"));
  const selectedTab = tabs?.find((tab) => tab.selected);

  const searchProps = selectedTab?.search || search;
  const filterProps = selectedTab?.filter || filter;
  const sortProps = selectedTab?.sort || sort;
  const actionsProps = selectedTab?.actions || actions;

  return (
    <Box width="100%">
      <StyledNavigationContainer variant={variant} {...props}>
        <Box flexDirection="row" alignItems="center" gap={!!tabs?.length ? "0" : isDownLg ? "100" : "200"}>
          {navs && isDownLg && !breadcrumb && !back && (
            <Button variation="flat" size="small" icon={<Icon name="Menu_01Icon" />} {...navs} />
          )}

          {breadcrumb ? (
            <Breadcrumbs items={breadcrumb} back={back} />
          ) : back ? (
            <Button
              variation="flat"
              size="small"
              text={t("global.back")}
              icon={<Icon name="ArrowLeftIcon" />}
              {...back}
            />
          ) : null}

          {text && (
            <Text preset={isDownLg ? "paragraph1-100" : "large200"} data-testid="page-title">
              {text}
            </Text>
          )}

          {tabs && <Tabs tabs={tabs} />}

          {logo && <Logo as="wordmark" size={62} />}
        </Box>

        {centerActions && (
          <StyledCenterNavigation>
            <Navs type="horizontal" items={centerActions} />
          </StyledCenterNavigation>
        )}

        <StyledRight>
          {savingStatus && <SavingStatus {...savingStatus} />}

          {!isDownMd && <Search search={searchProps} />}
          {!isDownMd && <Filter filter={filterProps} />}
          {!isDownMd && <Sort sort={sortProps} />}
          <Actions actions={actionsProps} />

          <StyledRightNavigation>
            {help && <Button variation="flat" size="small" icon={<Icon name="HelpCircleIcon" />} {...help} />}
            {feedback && <Button variation="flat" size="small" icon={<Icon name="Lightbulb_05Icon" />} {...feedback} />}
            {menu && (
              <Dropdown
                itemProps={{ size: isDownLg ? DropdownSize.SMALL : DropdownSize.LARGE }}
                position={isDownLg ? "auto-end" : "bottom-end"}
                size={DropdownSize.SMALL}
                px="50"
                {...menu}
              />
            )}
          </StyledRightNavigation>
        </StyledRight>
      </StyledNavigationContainer>
      {isDownMd && (searchProps || sortProps || filterProps) ? (
        <Box flexDirection="row" gap="100" py="150" px="200" borderBottom={`1px solid ${BorderColorDefault}`}>
          <Search search={searchProps} />
          <Filter filter={filterProps} />
          <Sort sort={sortProps} />
        </Box>
      ) : null}
    </Box>
  );
};

NavigationHeader.displayName = "Navigation Header";

export { NavigationHeader };
