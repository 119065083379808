import _kebabCase from "lodash/kebabCase.js";

import { inputDefaultTheme } from "ds/Form/src/InputDefaultTheme";

import { StyledSelectProps } from "./types";

const getDisabledStyles = (props: any) => {
  const styles = getStyles(props);

  return `
    cursor: not-allowed;
    ${styles}
    &:focus, &:focus-visible {
      ${styles}
    }
`;
};

const getStyles = (theme: any, p?: { disabled?: boolean; error?: boolean; search?: boolean; flat?: boolean }) =>
  Object.entries(theme).reduce(
    (styles, [key, value]) =>
      key === "focus"
        ? `${styles}\n&:focus, &:focus-visible {${getStyles(value)}}`
        : key === "error"
          ? p?.error
            ? `${styles}\n${getStyles(value)}`
            : styles
          : key === "disabled"
            ? p?.disabled
              ? `${styles}\n${getDisabledStyles(value)}`
              : styles
            : `${styles}\n${_kebabCase(key)}: ${value};`,
    ""
  );

export const getSelectStyles = ({ size, disabled, error }: StyledSelectProps) => `
  ${getStyles(theme.size[size], { disabled, error })}
  ${getStyles(theme.main, { disabled, error })}
  `;

export const theme = inputDefaultTheme;
