import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box } from "ds/Box";
import { Checkbox } from "ds/Checkbox";
import { Dropdown, DropdownItem } from "ds/Dropdown";
import { Modal } from "ds/Modal";
import { TextInput } from "ds/TextInput";
import { Icon } from "ds/icons";
import { NumberType, TNumberMin } from "graphqlQueries/number";
import { useModalContext } from "hooks/useModalContext";
import { useSelectionContext } from "hooks/useSelectionContext";

export const DuplicateJourneyModal = ({ numbers }: { numbers?: TNumberMin[] }) => {
  const { duplicateJourneys, updateModal } = useModalContext();
  const { setSelectedItems, selectedContent, setSelectedContent } = useSelectionContext();
  const [selectedNumber, setSelectedNumber] = useState<TNumberMin>();
  const [overwrite, setOverwrite] = useState(false);
  const [trigger, setTrigger] = useState(false);

  const [search, setSearch] = useState("");

  const { t } = useTranslation();

  const onDuplicate = () => {
    console.log("duplicate", setSelectedItems, selectedContent);
  };

  const onHide = () => {
    setSelectedContent(undefined);
    updateModal("duplicateJourneys", false);
  };

  const getDropdownContent = (numbers: TNumberMin[]) => {
    const sortedNumbers = numbers.sort(({ vname: A }, { vname: B }) => (A < B ? -1 : 1));

    return {
      productionNumbers: sortedNumbers.filter(({ numberType }) => numberType !== NumberType.VIRTUAL_NUMBER),
      virtualNumbers: sortedNumbers.filter(({ numberType }) => numberType === NumberType.VIRTUAL_NUMBER),
    };
  };

  const { productionNumbers, virtualNumbers } = getDropdownContent(numbers!);

  const filteredProductionNumbers = productionNumbers.filter(({ vname }) =>
    vname.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );
  const filteredVirtualNumbers = virtualNumbers.filter(({ vname }) =>
    vname.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );

  useEffect(() => {
    if (!duplicateJourneys) {
      setSelectedNumber(undefined);
      setOverwrite(false);
      setTrigger(false);
      setSelectedContent(undefined);
    }
  }, [duplicateJourneys, setSelectedContent]);

  return (
    <Modal
      type="STEPS"
      visible={duplicateJourneys}
      title={t("content.stack-containers.duplicate.modal.title")}
      onHide={onHide}
      style={{ width: "529px" }}
      primaryAction={{
        text: t("btn.duplicate"),
        // loading: true,
        onClick: onDuplicate,
        disabled: !selectedNumber,
      }}
      secondaryAction={{
        text: t("btn.cancel"),
        onClick: onHide,
      }}
    >
      <Box gap="200">
        <Dropdown
          // disabled={disabled || loading}
          trigger={
            <TextInput
              placeholder={t("content.stack-containers.duplicate.modal.select")}
              iconRight={<Icon name="ChevronDownIcon" />}
              onChange={(e) => setSearch(e.target.value)}
              value={search || selectedNumber?.vname}
            />
          }
          drop={{ useTriggerWidth: true }}
          dropProps={{ style: { maxHeight: "300px" } }}
          footer={filteredVirtualNumbers?.map((item) => (
            <DropdownItem
              key={item.uuid}
              label={item.vname}
              description={t("global.virtual-number")}
              avatar={{ label: item.vname, url: item.profilePicture! }}
              onClick={() => {
                setSearch("");
                setSelectedNumber(item);
              }}
              selected={item.uuid === selectedNumber?.uuid}
            />
          ))}
        >
          {filteredProductionNumbers?.map((item) => (
            <DropdownItem
              key={item.uuid}
              label={item.vname}
              description={item.fromAddr}
              avatar={{ label: item.vname, url: item.profilePicture! }}
              onClick={() => {
                setSearch("");
                setSelectedNumber(item);
              }}
              selected={item.uuid === selectedNumber?.uuid}
            />
          ))}
        </Dropdown>
        <Checkbox
          text={t("content.stack-containers.duplicate.modal.overwrite")}
          checked={overwrite}
          onChange={(e) => setOverwrite(e.target.checked)}
        />
        <Checkbox
          text={t("content.stack-containers.duplicate.modal.trigger")}
          checked={trigger}
          onChange={(e) => setTrigger(e.target.checked)}
        />
      </Box>
    </Modal>
  );
};
