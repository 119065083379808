import { Box, Form, Layer, Text, TextInput } from "grommet";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Button } from "ds/Button";
import { composedPath, useClickOutside } from "hooks/useClickOutside";
import { turnTheme } from "theme";
import { devices } from "utils/breakpoints";

import { ButtonFilledBig, ButtonIconMute, ButtonTextMute } from "../buttons";
import { Close } from "../icons/Close";

/* STYLES
------------------------------------------ */
const StyledModalContainer = styled(Box)`
  padding: ${({ pad }) => pad || "24px 0 16px"};
  width: ${({ width }) => width};
  @media ${devices.mobileL} {
    width: 100%;
    height: 100%;
  }
`;
const StyledModalHeader = styled(Box)`
  position: relative;
  margin: 0 32px 6px;
`;

const StyledCloseContainer = styled(Box)`
  position: absolute;
  right: -24px;
  top: -16px;
`;
/* COMPONENTS
------------------------------------------ */

export const PlainModal = ({
  children,
  hideHeader = false,
  useCloseEvent = false,
  containerPad = "24px 0 16px",
  containerProps = {},
  disableClickOutside = false,
  full = false,
  width = "medium",
  pad = "0 32px 0 32px",
  modalContainerProps = {},
  onClose = () => {},
  container = true,
  ...props
}) => {
  const [clickOutsideRef] = useClickOutside(onClose, {
    ignoreQuery: ["ignore-onclickoutside"],
    skip: !useCloseEvent,
  });
  return (
    <Layer
      onEsc={onClose}
      onClickOutside={
        useCloseEvent || disableClickOutside
          ? undefined
          : (e) => {
              if (!composedPath(e, "ignore-onclickoutside")) {
                onClose();
              }
            }
      }
      position="center"
      full={full}
      {...props}
    >
      <StyledModalContainer
        width={width}
        elevation="large"
        overflow="auto"
        fill={full}
        {...props}
        pad={containerPad}
        {...modalContainerProps}
        ref={clickOutsideRef}
      >
        {/* Modal Header */}
        {!hideHeader && (
          <StyledModalHeader direction="row" align="center">
            <StyledCloseContainer>
              <ButtonIconMute
                onClick={onClose}
                icon={<Close fill={turnTheme.global.colors["dark-4"]} />}
                data-testid="close-button"
              />
            </StyledCloseContainer>
          </StyledModalHeader>
        )}
        {/* Modal Content */}
        {container ? (
          <Box pad={pad} gap="small" flex="grow" {...containerProps}>
            {children}
          </Box>
        ) : (
          children
        )}
      </StyledModalContainer>
    </Layer>
  );
};

export const FormModal = ({
  confirmText = "Submit",
  width,
  loading,
  cancelable = true,
  onSubmit = () => {},
  onClose = () => {},
  children,
  disabled,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <PlainModal onClose={onClose} width={width}>
      <Form disabled={disabled} onSubmit={onSubmit} {...props}>
        {children}

        {/* Modal Footer */}
        <Box direction="row" gap="8px" pad="0px">
          <Button disabled={loading || disabled} loading={loading} type="submit" text={confirmText} size="small" />
          {cancelable && <Button size="small" variation="secondary" text={t("btn.no-cancel")} onClick={onClose} />}
        </Box>
      </Form>
    </PlainModal>
  );
};

export const CardModal = ({ content, footerContent = undefined, width, onClose = () => {}, ...props }) => {
  return (
    <PlainModal onClose={onClose} width={width} {...props}>
      {/* Modal Content */}
      <Box margin="0 32px" gap="small">
        {content}
      </Box>

      {/* Modal Footer */}
      {footerContent && (
        <Box direction="row" gap="8px" pad="24px 32px">
          {footerContent}
        </Box>
      )}
    </PlainModal>
  );
};

export const ConfirmModal = ({
  onCancel = undefined,
  onClose,
  onConfirm,
  loading,
  question,
  confirmText,
  ...props
}) => {
  const { t } = useTranslation();
  onCancel = onCancel ? onCancel : onClose;
  return (
    <CardModal
      width="large"
      onClose={onClose}
      content={<Text size="xlarge">{question}</Text>}
      className="ignore-onclickoutside"
      footerContent={
        <Box direction="row" gap="8px">
          <Button
            loading={loading}
            disabled={loading}
            text={confirmText}
            onClick={onConfirm}
            destructive
            className="ignore-onclickoutside"
          />
          <Button
            variation="secondary"
            className="ignore-onclickoutside"
            text={t("btn.no-cancel")}
            onClick={onCancel}
          />
        </Box>
      }
      {...props}
    />
  );
};

export const EditValueModal = ({ onCancel, onClose, onSave, loading, icon, text, saveText, initialValue = "" }) => {
  const { t } = useTranslation();

  const [newValue, setNewValue] = useState(initialValue);
  onCancel = onCancel ? onCancel : onClose;

  return (
    <CardModal
      data-testid="edit-value-modal"
      width="medium"
      onClose={() => onClose()}
      content={
        <Box gap="small" fill={true}>
          <Text size="xlarge" padding>
            {text}
          </Text>
          <TextInput
            value={newValue}
            onChange={(event) => {
              setNewValue(event.target.value);
            }}
            data-testid="card-modal-text-input"
          />
        </Box>
      }
      footerContent={
        <Box gap="8px" direction="row">
          <ButtonFilledBig
            warning
            label={saveText ?? t("btn.save")}
            disabled={loading}
            icon={icon}
            onClick={() => onSave(newValue)}
            data-testid="card-modal-ok-button"
          />
          <ButtonTextMute
            disabled={loading}
            label={t("btn.cancel")}
            onClick={() => onCancel()}
            data-testid="card-modal-cancel-button"
          />
        </Box>
      }
    />
  );
};
