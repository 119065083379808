import _debounce from "lodash/debounce.js";
import React, { useRef } from "react";

import { Box } from "ds/Box";
import { Button } from "ds/Button";
import { Dropdown } from "ds/Dropdown";
import { Tooltip } from "ds/Tooltip";
import { Text } from "ds/Typography";
import { Icon } from "ds/icons";

import { BreadcrumbsButton, BreadcrumbsInput, StyledBreadcrumbsContainer, StyledInputText } from "../styles";
import { BreadcrumbsItemProps } from "../types";

export const BreadcrumbsItem = ({
  current,
  text,
  onEdit,
  onClick,
  tooltip,
  menu,
  inputStyle,
}: BreadcrumbsItemProps) => {
  const debouncedOnEdit = onEdit && _debounce(onEdit, 500);
  const inputRef = useRef<HTMLInputElement>();
  const spanRef = useRef<HTMLSpanElement>();

  const updateInputSize = () => {
    if (spanRef.current) {
      spanRef.current.innerHTML = inputRef.current?.value || "";
    }

    if (inputRef.current && spanRef.current?.clientHeight) {
      inputRef.current.style.width = `${spanRef.current?.clientWidth}px`;
    }
  };

  const saveInputRef = (ref: HTMLInputElement) => {
    inputRef.current = ref;
    updateInputSize();
  };

  const saveSpanRef = (ref: HTMLSpanElement) => {
    spanRef.current = ref;
    setTimeout(updateInputSize, 100);
  };

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    updateInputSize();
    debouncedOnEdit?.(e.target.value);
  };

  const renderMenu = () =>
    menu && (
      <Dropdown
        position="bottom"
        trigger={<Button variation="flat" icon={<Icon name="ChevronDownIcon" />} size="micro" />}
        {...menu}
      />
    );

  return onEdit ? (
    <Tooltip
      trigger={
        <StyledBreadcrumbsContainer useMenu={!!menu}>
          <BreadcrumbsInput
            ref={saveInputRef}
            defaultValue={text}
            onChange={onChange}
            current={current}
            useMenu={!!menu}
            data-testid="breadcrumbs-input"
            style={inputStyle}
          />
          <StyledInputText ref={saveSpanRef} useMenu={!!menu}>
            {text}
          </StyledInputText>
          {renderMenu()}
        </StyledBreadcrumbsContainer>
      }
      {...tooltip}
    >
      {tooltip?.content ?? null}
    </Tooltip>
  ) : (
    <Box flexDirection="row" alignItems="center" gap="50">
      <BreadcrumbsButton onClick={onClick} current={current} useMenu={!!menu}>
        <Text preset="medium100">{text}</Text>
      </BreadcrumbsButton>
      {renderMenu()}
    </Box>
  );
};
