import React, { FC, createContext, useCallback, useState } from "react";

import { FeatureFlag, isEnabledFeature } from "utils/featureFlags";
import { saveStorageSwitch } from "utils/localStorage";

import { InfoPortugueseLocaleModal } from "./InfoPortugueseLocaleModal";
import { DEFAULT_LOCALE, INFO_PORTUGUESE_MODAL_LOCAL_STORAGE_KEY, localeOptions } from "./constants";
import { getLocaleCookie } from "./utils/cookies";
import { applyLocale, initLocale } from "./utils/locale";

export const LocaleContext = createContext<{
  localeOptions: {
    value: string;
    label: string;
  }[];
  locale: string;
  onChangeLocale: (value: string) => void;
  onLocationChanged: () => void;
}>({
  localeOptions,
  locale: DEFAULT_LOCALE,
  onChangeLocale: () => null,
  onLocationChanged: () => null,
});

export const LocaleProvider: FC = ({ children }) => {
  const [isVisibleInfoPortugueseModal, setVisibleInfoPortugueseModal] = useState(false);
  const openModal = () => setVisibleInfoPortugueseModal(true);
  const closeModal = () => setVisibleInfoPortugueseModal(false);

  const [locale, setLocale] = useState(() => initLocale({ onPortugueseDetected: openModal }));

  const onChangeLocale = useCallback((locale) => {
    setLocale(locale);
    applyLocale(locale, true);
  }, []);

  const onLocationChanged = useCallback(() => {
    if (isEnabledFeature(FeatureFlag.LOCALES) && !getLocaleCookie()) {
      const detectedLocale = initLocale({ onPortugueseDetected: openModal });

      if (detectedLocale !== locale) {
        setLocale(detectedLocale);
      }
    }
  }, [locale]);

  return (
    <LocaleContext.Provider
      value={{
        localeOptions,
        locale,
        onChangeLocale,
        onLocationChanged,
      }}
    >
      <InfoPortugueseLocaleModal
        visible={isVisibleInfoPortugueseModal}
        onAcceptPortuguese={() => {
          onChangeLocale("pt");
          saveStorageSwitch(INFO_PORTUGUESE_MODAL_LOCAL_STORAGE_KEY, true);
          closeModal();
        }}
        onKeepEnglish={() => {
          onChangeLocale("en");
          saveStorageSwitch(INFO_PORTUGUESE_MODAL_LOCAL_STORAGE_KEY, true);
          closeModal();
        }}
      />
      {children}
    </LocaleContext.Provider>
  );
};
