import _kebabCase from "lodash/kebabCase.js";

import {
  BackgroundColorBerry,
  BackgroundColorCream,
  BackgroundColorLavender,
  BackgroundColorLime,
  BackgroundColorOrange,
  BackgroundColorSky,
  BackgroundColorSun,
} from "ds/Alias";
import { ColorBrandDefault } from "ds/Core";

import { LogoProps, LogoTypes } from "./types";

interface StylesProps {
  backgroundColor: string;
}

const getStyles = (props: StylesProps) =>
  Object.entries(props).reduce(
    (styles, [key, value]) =>
      key === "hover" ? `${styles}\n&:hover {${getStyles(value)}}` : `${styles}\n${_kebabCase(key)}: ${value};`,
    ""
  );

export const getLogoStyles = ({ type }: LogoProps) => `
    ${type ? getStyles(theme.type[type]) : ""}
  `;

export const theme = {
  type: {
    [LogoTypes.DEFAULT]: {
      backgroundColor: ColorBrandDefault,
    },
    [LogoTypes.CREAM]: {
      backgroundColor: BackgroundColorCream,
    },
    [LogoTypes.LIME]: {
      backgroundColor: BackgroundColorLime,
    },
    [LogoTypes.BERRY]: {
      backgroundColor: BackgroundColorBerry,
    },
    [LogoTypes.SUN]: {
      backgroundColor: BackgroundColorSun,
    },
    [LogoTypes.SKY]: {
      backgroundColor: BackgroundColorSky,
    },
    [LogoTypes.LAVENDER]: {
      backgroundColor: BackgroundColorLavender,
    },
    [LogoTypes.ORANGE]: {
      backgroundColor: BackgroundColorOrange,
    },
  },
};
