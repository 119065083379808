import React from "react";

import { ColorBerry600 } from "ds/Core";

import { StyledAvatar } from "./Styles";
import { AvatarProps, AvatarSizes } from "./types";

const Avatar = ({ label, size = AvatarSizes.MEDIUM, color = ColorBerry600, url, showLabel, ...props }: AvatarProps) => {
  const cutLabel = (label || "Undefined").substring(0, 1);

  return (
    <StyledAvatar title={showLabel ? label : undefined} size={size} color={color} url={url} {...props}>
      {!url && <span>{cutLabel}</span>}
    </StyledAvatar>
  );
};

Avatar.displayName = "Avatar";

export { Avatar };
