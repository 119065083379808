import styled from "styled-components";

import { TextColorDefault, TextColorSubtle } from "ds/Alias";
import {
  BorderRadius200,
  ColorNeutralBlack,
  ColorNeutralWhite,
  FontFamilyInter,
  FontSize100,
  Space50,
  Space150,
  Space200,
} from "ds/Core";
import { FormColorBorderDefault, FormColorBorderFocus } from "ds/Form";

import { BreadcrumbsButtonProps, BreadcrumbsInputProps } from "./types";

export const BreadcrumbsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const BreadcrumbsButton = styled.button<BreadcrumbsButtonProps & { useMenu?: boolean }>`
  box-sizing: border-box;
  height: 48px;
  padding: ${Space150} ${Space200};
  color: ${({ current }) => (current ? TextColorDefault : TextColorSubtle)};
  border: none;
  background-color: unset;

  ${({ useMenu }) => useMenu && "padding-right: 0px;"}

  &:hover {
    color: ${TextColorDefault};
    ${({ onClick }) => onClick && "cursor: pointer;"}
  }
`;

export const BreadcrumbsInput = styled.input<BreadcrumbsInputProps & { useMenu?: boolean }>`
  box-sizing: border-box;
  height: 32px;
  color: ${ColorNeutralBlack};
  border: none;
  border-radius: ${BorderRadius200};
  background-color: unset;
  font-size: ${FontSize100};
  font-family: ${FontFamilyInter};
  padding: ${Space50} ${({ useMenu }) => (useMenu ? `calc(24px + ${Space50} + ${Space200})` : Space50)} ${Space50}
    ${Space50};
  min-width: 50px;
  max-width: 30vw;

  &:hover,
  &:focus {
    color: ${ColorNeutralBlack};
    background: ${ColorNeutralWhite};
  }
  &:hover {
    outline: 1px solid ${FormColorBorderDefault};
    cursor: pointer;
  }
  &:focus {
    outline: 1px solid ${FormColorBorderFocus};
    cursor: text;
  }
`;

export const StyledBreadcrumbsContainer = styled.div<{ useMenu?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;

  ${({ useMenu }) =>
    useMenu &&
    `
    > div {
      position: absolute;
      right: ${Space200};
      width: 24px;
      height: 24px;
    }
  `}
`;

export const StyledInputText = styled.span<{ useMenu?: boolean }>`
  position: absolute;
  visibility: hidden;
  font-size: ${FontSize100};
  font-family: ${FontFamilyInter};
  padding: ${Space50} ${({ useMenu }) => (useMenu ? `calc(24px + ${Space50} + ${Space200})` : Space50)} ${Space50}
    ${Space50};
  min-width: 50px;
  text-wrap: nowrap;
  max-width: 30vw;
`;
