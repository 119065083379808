import { TextInput } from "grommet";
import styled from "styled-components";

import {
  BorderRadius200,
  BorderWidth100,
  ColorGray500,
  ColorNeutralBlack,
  ColorNeutralWhite,
  FontSize200,
  LineHeightStandard,
} from "ds/Core";
import { turnTheme } from "theme";

export const AutocompleteWrapper = styled.div`
  position: relative;
  flex-grow: 1;
`;

export const SearchInput = styled(TextInput)`
  background: ${ColorNeutralWhite};
  border: none;
  border: ${BorderWidth100} solid ${ColorGray500};
  border-radius: ${BorderRadius200};
  color: ${ColorNeutralBlack};
  font-weight: 400;
  font-size: ${FontSize200};
  line-height: ${LineHeightStandard};
  max-height: 48px;
  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`;

export const AutocompletePanel = styled.div<{ left: string }>`
  position: absolute;
  left: ${({ left }) => `${left}px`};
  width: 100%;
  max-width: 20rem;
  border-radius: 0.5rem;
  background-color: ${ColorNeutralWhite};
  box-shadow:
    0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  z-index: 1;
`;

export const AutocompleteSource = styled.div`
  margin-top: 0.5rem;
  width: 100%;
  overflow: hidden;
  border-radius: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

export const AutocompleteItems = styled.ul`
  height: 100%;
  max-height: 24rem;
  overflow-y: scroll;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const AutocompleteItem = styled.div<{ selected: boolean }>`
  cursor: pointer;
  flex-direction: column;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background: ${({ selected }) => (selected ? turnTheme.global.colors["light-3"] : turnTheme.global.colors.white)};
`;
