import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import { Tooltip } from "ds/Tooltip";
import { RoleType } from "graphql/generated/types";
import { useGlobalContext } from "hooks";

import { StyledRoleGuardWrapper } from "./styles";

type RoleGuardProps = {
  allowedRoles?: RoleType[];
  hideElement?: boolean;
};

export const RoleGuard = ({ allowedRoles: roles, children, hideElement }: PropsWithChildren<RoleGuardProps>) => {
  const { role: currentRole, isAdmin } = useGlobalContext();
  const { t } = useTranslation();
  if (!roles || isAdmin) {
    return <>{children}</>;
  }
  if (currentRole && !roles?.includes(currentRole)) {
    if (hideElement) return null;
    return (
      <Tooltip placement="right" trigger={<StyledRoleGuardWrapper>{children}</StyledRoleGuardWrapper>}>
        {t("global.feature-access-denied")}
      </Tooltip>
    );
  }
  return <>{children}</>;
};
