import _cloneDeep from "lodash/cloneDeep.js";
import _kebabCase from "lodash/kebabCase.js";
import _merge from "lodash/merge.js";

import { Space400, Space600, Space700 } from "ds/Core";
import { inputDefaultTheme } from "ds/Form";

import { StyledInputProps } from "./types";

const getDisabledStyles = (props: any) => {
  const styles = getStyles(props);

  return `
    cursor: not-allowed;
    ${styles}
    &:focus, &:focus-visible {
      ${styles}
    }
`;
};

export const theme = _merge(_cloneDeep(inputDefaultTheme), {
  size: {
    xsmall: {
      iconLeft: { paddingLeft: Space400 },
      iconRight: { paddingRight: Space400 },
    },
    small: {
      iconLeft: { paddingLeft: Space600 },
      iconRight: { paddingRight: Space600 },
    },
    medium: {
      iconLeft: { paddingLeft: Space600 },
      iconRight: { paddingRight: Space600 },
    },
    large: {
      iconLeft: { paddingLeft: Space700 },
      iconRight: { paddingRight: Space700 },
    },
  },
  main: {
    flat: {
      borderStyle: "none",
    },
  },
});

const getStyles = (theme: any, { disabled, error, iconLeft, iconRight, flat }: Partial<StyledInputProps> = {}) =>
  Object.entries(theme).reduce(
    (styles, [key, value]) =>
      key === "flat"
        ? flat
          ? `${styles}\n${getStyles(value)}`
          : styles
        : key === "iconLeft"
          ? iconLeft
            ? `${styles}\n${getStyles(value)}`
            : styles
          : key === "iconRight"
            ? iconRight
              ? `${styles}\n${getStyles(value)}`
              : styles
            : key === "focus"
              ? `${styles}\n&:focus, &:focus-visible {${getStyles(value)}}`
              : key === "error"
                ? error
                  ? `${styles}\n${getStyles(value)}`
                  : styles
                : key === "disabled"
                  ? disabled
                    ? `${styles}\n${getDisabledStyles(value)}`
                    : styles
                  : `${styles}\n${_kebabCase(key)}: ${value};`,
    ""
  );

export const getTextInputStyles = ({ inputSize, disabled, error, iconLeft, iconRight, flat }: StyledInputProps) => `
  ${getStyles(theme.size[inputSize], { disabled, error, iconLeft, iconRight })}
  ${getStyles(theme.main, { disabled, error, flat })}
  `;
