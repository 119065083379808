import React from "react";

import { typographyTokens } from "ds/theme/typography-tokens";

import { StyledTypography } from "./Styles";
import { TypographyProvider } from "./TypographyProvider";
import { TextProps } from "./types";
import { useTypographyContext } from "./useTypographyContext";

const Text = React.forwardRef<HTMLSpanElement, TextProps>(
  ({ children, preset, fontFamily = "inter", ...rest }, ref) => {
    const contextProps = useTypographyContext();
    const styles = preset ? typographyTokens.copy[preset] : {};
    return (
      <TypographyProvider fontFamily={fontFamily} {...styles} {...contextProps} {...rest}>
        <StyledTypography
          fontFamily={fontFamily}
          {...styles}
          {...contextProps}
          {...rest}
          ref={ref}
          as="span"
          dir="auto"
        >
          {children}
        </StyledTypography>
      </TypographyProvider>
    );
  }
);

Text.displayName = "Text";

export { Text };
