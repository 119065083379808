import _isFunction from "lodash/isFunction.js";
import _isObject from "lodash/isObject.js";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { Dropdown, DropdownItem } from "ds/Dropdown";
import { TextInput } from "ds/TextInput";

import { ContentStyles, HeaderStyles, StyledLabel, StyledRightIcon, StyledSelectButton, Tag } from "./styles";
import { ProcessedOption, SelectMultipleProps, SelectProps, SelectSingleProps } from "./types";

export function Select<TValue extends TOption[keyof TOption], TOption>(
  props: SelectMultipleProps<TValue, TOption>
): React.ReactElement;
export function Select<TValue extends TOption[keyof TOption], TOption>(
  props: SelectSingleProps<TValue, TOption>
): React.ReactElement;
export function Select<
  TValue extends TOption[keyof TOption] | TOption[keyof TOption][],
  TOption = Record<string, string>,
>(props: SelectProps<TValue, TOption>): React.ReactElement;

export function Select<
  TValue extends TOption[keyof TOption] | TOption[keyof TOption][],
  TOption = Record<string, string>,
>({
  size = "medium",
  placeholder,
  onChange,
  onHide,
  value,
  options = [],
  uniqueKey,
  valueKey = "value",
  labelKey = "label",
  label,
  multiple,
  filter,
  filterType = "inline",
  error,
  position,
  disabled,
  disableSelected,
  closeOnClick,
  dropProps,
  drop,
  tag,
  triggerProps,
  ...rest
}: SelectProps<TValue, TOption>) {
  const ref = useRef<HTMLInputElement>(null);
  const objectOptions = _isObject(options[0]);

  const { getValue, getLabel, getKey } = useMemo(() => {
    function getByKey<T>(type: "valueKey" | "labelKey" | "uniqueKey") {
      return (option?: TOption, fallback?: T) => {
        const keys = { valueKey, labelKey, uniqueKey };
        const key = keys[type];

        return (
          _isFunction(key) && option //
            ? key(option)
            : objectOptions && key
              ? (option?.[key as string] ?? fallback)
              : !objectOptions
                ? option
                : (fallback ?? option)
        ) as T;
      };
    }

    return {
      getValue: getByKey<TValue>("valueKey"),
      getLabel: getByKey<string>("labelKey"),
      getKey: getByKey<string | number>("uniqueKey"),
    };
  }, [objectOptions, valueKey, labelKey, uniqueKey]);

  const onClickItem = (option: TOption, selected: boolean) => {
    if (multiple) {
      const itemValue = getValue(option);
      const prevValue = value ?? [];

      onChange?.(selected ? prevValue.filter((value) => value !== itemValue) : [...prevValue, itemValue], option);
    } else if (!selected) {
      // context(alexandrchebotar, 2022-12-28): typescript can't infer correct handler, but it shows error when you try to pass multiple onChange handler to non-multiple Select
      (onChange as (value: TValue, option: TOption) => void)?.(getValue(option), option);
    } else if (selected && !disableSelected) {
      // todo(Pringels, 2024-05-28): find out why this change handler needs to fire with undefined here
      onChange?.(undefined as any, undefined as any);
    }
  };

  const innerFilterRef = useRef<HTMLInputElement>(null);

  const [filterValue, setFilterValue] = useState("");

  const [open, setOpen] = useState(false);
  const onCloseDropdown = () => {
    setOpen(false);
    setFilterValue("");
    onHide?.();
  };

  useEffect(() => {
    if (open) {
      innerFilterRef.current?.focus();
    }
  }, [open]);

  const processedOptions = useMemo(
    (): ProcessedOption<TOption>[] =>
      options.map((option, index) => ({
        option,
        key: getKey(option, index),
        selected: multiple ? value?.includes(getValue(option)) || false : getValue(option) === value,
        description: option["description"],
        disabled: option["disabled"],
      })),
    [getKey, getValue, multiple, options, value]
  );

  const filteredOptions = useMemo(
    () =>
      processedOptions.filter(({ option }) =>
        filterValue ? getLabel(option)?.toLowerCase().includes(filterValue.toLowerCase()) : true
      ),
    [filterValue, getLabel, processedOptions]
  );

  const getMultipleLabel = () =>
    (value as TValue[])?.map((value, index) =>
      tag ? (
        React.cloneElement(tag, {
          children: getLabel(options.find((option) => getValue(option) === value)),
          ...options.find((option) => getValue(option) === value),
        })
      ) : (
        <Tag key={index}>{getLabel(options.find((option) => getValue(option) === value))}</Tag>
      )
    );

  const triggerLabel = label
    ? label
    : multiple
      ? getMultipleLabel()
      : (getLabel(processedOptions.find(({ selected }) => selected)?.option) ?? "");

  useEffect(() => {
    if (ref.current) {
      if (rest.visible) {
        ref.current.focus();
        ref.current.value = typeof triggerLabel === "string" ? triggerLabel : "";
      } else if (typeof rest.visible === "boolean") {
        ref.current.blur();
        if (!triggerLabel) {
          ref.current.value = "";
        }
      }
    }

    // context(@Puvvl, 2024-01-29): Only need to run whne visible chamge
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.visible]);

  return (
    <Dropdown
      size={size === "medium" ? "large" : size === "xsmall" ? "small" : size}
      label={triggerLabel ?? placeholder}
      disabled={disabled}
      onShow={() => setOpen(true)}
      onHide={onCloseDropdown}
      visible={open}
      trigger={
        filter && filterType === "inline" && !multiple ? (
          <TextInput
            size={size}
            value={open ? filterValue : (triggerLabel as string)}
            onChange={(e) => setFilterValue(e.target.value)}
            placeholder={placeholder}
            ref={ref}
            iconRight={
              <StyledRightIcon
                name="ChevronRightIcon"
                position={position || "auto"}
                color={disabled ? "gray700" : "neutralBlack"}
              />
            }
            {...triggerProps}
          />
        ) : (
          <StyledSelectButton size={size} error={error} isPlaceholder={!triggerLabel} multiple={multiple} type="button">
            <StyledLabel>{triggerLabel?.length ? triggerLabel : placeholder}</StyledLabel>
            <StyledRightIcon
              name="ChevronRightIcon"
              position={position || "auto"}
              color={disabled ? "gray700" : "neutralBlack"}
            />
          </StyledSelectButton>
        )
      }
      closeOnClick={closeOnClick ?? !multiple}
      dropProps={{
        maxHeight: "240px",
        updatePosition: multiple ? triggerLabel : false,
        ...dropProps,
      }}
      drop={{
        popper: { strategy: "absolute" },
        useTriggerWidth: true,
        style: { overflow: "hidden" },
        ...drop,
      }}
      header={
        filter &&
        (filterType === "inside" || multiple) && (
          <TextInput
            ref={innerFilterRef}
            onChange={(e) => setFilterValue(e.target.value)}
            placeholder="Search"
            type="search"
            flat
            size={size === "xsmall" ? "small" : size}
            containerProps={{ className: "drop-content" }}
          />
        )
      }
      contentProps={{ style: ContentStyles }}
      headerProps={{ style: HeaderStyles }}
      {...rest}
    >
      {filteredOptions.map(({ option, key, selected, description, disabled = false }) => (
        <DropdownItem
          key={key}
          label={getLabel(option)}
          onClick={() => onClickItem(option, selected)}
          selected={selected}
          data-testid={`${rest["data-testid"] ?? "select"}-option`}
          description={description}
          disabled={disabled}
        />
      ))}
    </Dropdown>
  );
}

/* todo(alexandrchebotar, 2022-11-16):
        1. Types
        2. Docs
        3. Multiple selection (design required)
        4. Add ref forwarding (DropdownItem update required)
        5. Lazy options loading?
        6. Debounced filter: switching input mode controlled mode issue:
          - [implemented for now] create extra filter value state
          - create local TextInput wrapper with dabouced callback
          - use two inputs: controlled that only shows trigger label and uncontrolled with debouncing
        7. Render multiple label tags (requires TextInput update)

*/
