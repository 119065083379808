import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import LoginImage from "assets/onboarding/login.svg";
import { OnboardingScreen } from "containers/Onboarding/components";
import { OnboardingContent } from "containers/Onboarding/styles";
import { StepColors } from "containers/Onboarding/styles";
import { Box } from "ds/Box";
import { Button } from "ds/Button";
import { Space200 } from "ds/Core";
import { TextInput } from "ds/TextInput";
import { Text } from "ds/Typography";
import { calcSpacing, devices } from "lib/impact";

const MFASignInContent = styled(OnboardingContent)`
  display: flex;
  height: calc(100% - ${calcSpacing(["xl", "m", "xl", "m"])});
  padding-bottom: ${calcSpacing(["xl", "m"])};
  justify-content: center;

  @media ${devices.tablet} {
    display: block;
    height: auto;
  }
`;

export const MFASignIn = ({ setMfaCode }) => {
  const { t } = useTranslation();
  const [code, setCode] = useState("");

  return (
    <OnboardingScreen
      color={StepColors.LOGIN}
      image={LoginImage}
      contentContainer={MFASignInContent}
      showLogo
      hideSupport
    >
      <Box justifyContent="center" alignItems="flex-start" height="100vh">
        <Text fontSize="1000" color="neutralBlack" padding="200" fontWeight="200" lineHeight="standard">
          {t("login.2fa-login.title")}
        </Text>
        <Text fontSize="300" color="neutralBlack" padding="100" lineHeight="standard">
          {t("login.2fa-login.subtitle")}
        </Text>
        <TextInput
          onChange={(event) => setCode(event.target.value)}
          placeholder={t("login.input-placeholder")}
          style={{ margin: Space200, width: "350px", letterSpacing: "12px" }}
        />
        <Button
          variation="primary"
          size="medium"
          text={t("btn.continue")}
          marginLeft="200"
          marginTop="400"
          style={{ borderRadius: "32px" }}
          onClick={() => setMfaCode(code)}
          disabled={code.length !== 6}
        />
      </Box>
    </OnboardingScreen>
  );
};
